<template>
  <div class="container grid-md products">
    <div class="columns">
      <div class="column">
        <div class="tabs">
          <div class="tabs__navigation tabs__navigation--sub-page">
            <router-link class="tabs__navigation-item" to="/service/nordic-minds-search">{{ $t('nordic_minds_search.label') }}</router-link>
            <router-link class="tabs__navigation-item" to="/service/executive-search">{{ $t('executive_search.label') }}</router-link>
            <router-link class="tabs__navigation-item" to="/service/board-services">{{ $t('board_services.label') }}</router-link>
          </div>
          <div class="tabs__content">
            <div class="tabs__content-item">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Expertise',
  data () {
    return {
      activeTab: 1
    }
  },
  components: {},
}
</script>

<style lang="scss" scoped>
.intro {
  padding: 2rem 0 4rem 0;
  @media (min-width: $size-md + 1px) {
    min-height: 18.75rem;
  }
}

.column.col-5.col-sm-12.col-md-8.col-lg-5 {
  max-width: 25.8rem;
}
</style>
